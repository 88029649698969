<template>
  <div class="checkbox-detail">
    <h1 class="checkbox-detail__title">
      Отчет об изменениях прав на объект недвижимости
    </h1>
    <p class="description">
      Данный отчёт содержит полную информацию о бывших владельцах, а также даты
      перехода прав и причины их смены.
    </p>
    <ul>
      <li>
        <span class="detail-icon">1</span>
        <b>ФИО всех собственников с 1998 года</b>
      </li>
      <li>
        <span class="detail-icon">2</span>
        <b>Серию и номер свидетельства о регистрации</b>
      </li>
      <li>
        <span class="detail-icon">3</span>
        <b>Даты перехода прав</b>
      </li>
      <li>
        <span class="detail-icon">4</span>
        <b>Дополнительная информация</b>
      </li>
      <li>
        <span class="detail-icon">5</span>
        <b>Данные о каждом из Правообладателей.</b>
      </li>
    </ul>
    <div class="info">
      <div class="price">
        <p>Стоимость:</p>
        <h6>{{ cost }} ₽</h6>
      </div>
      <div class="term">
        <p>Срок исполнения:</p>
        <h6></h6>
      </div>
      <div class="format">
        <p>Формат:</p>
        <h6>Электронный документ</h6>
      </div>
    </div>
  </div>
</template>

<script>
import popup from '@/mixins/popup';
import './checkboxDetail.scss';

export default {
  name: 'CheckboxDetailSOPP',
  mixins: [popup],
  computed: {
    cost() {
      return this.$store.state.config.cost.SOPP;
    }
  }
};
</script>
